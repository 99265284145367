import React from "react";
// import "./Layout.css";

// Pass the child props
export default function Footer() {
  return (
    <footer id="footer" className="footer">
    <div className="copyright">
      &copy; Copyright <strong><span>Kitchen Holiday</span></strong>. All Rights Reserved
    </div>
    <div className="credits">
    With Love by <a href="#">Ree</a>
    </div>
  </footer>

  
  );
}