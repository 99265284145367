import React, { useEffect, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { getCategories, addItem, getItem, getItems, itemMappingsData, getSubItems } from '../../store/itemsSlice'; 
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Form, Card, Modal } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addUserAddress, getUserAddresses, getUsers } from '../../store/usersSlice';
import { addOrder, addUserOrder } from '../../store/orderSlice';
import { getSubscriptions, getOrderDates, getZones } from '../../store/subscriptionsSlice';
import { getDeliverySlots } from '../../store/adminSlice';
import Map from './map';

function AddOrder() {
    const { error, items, subItems } = useSelector((state) => state.items)
    const { users, userAddresses } = useSelector((state) => state.users)
    const { subscriptions, lastSubDate } = useSelector((state) => state.subscriptions)
    const { deliverySlots } = useSelector((state) => state.admin)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [validated, setValidated] = useState(false);
    const [userId , setUserId] = useState('')
    const [itemId , setItemId] = useState('')
    const [mappings , setMappings] = useState([])
    const [showPopup, setShow] = useState(false);
    const [selectedSubId, setSelectedSubId] = useState(null);
    const [extraItemCountData, setExtraItemCountData] = useState({});
    const [extraSubItems, setExtraSubItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedSubscription, setSelectedSubscription] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState('');
    const [plan, setPlan] = useState();
    const [deliverySlot, setDeliverySlot] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [addressId, setAddressId] = useState(0)
    const [address, setAddress] = useState()
    const [newAddress, setNewAddress] = useState(false)
    const [addedExtraSubItems, setAddedExtraSubItems] = useState([]);
    const [isPickFromKitchen, setIsPickFromKitchen] = useState(false);
    
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [fName, setFName] = useState('')
    const [lName, setLName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [country, setCountry] = useState('')
    const [stateValue, setStateValue] = useState('')
    const [zip, setZip] = useState('')
    const [location, setLocation] = useState()
    const [allowedZipCodes, setAllowedZipCodes] = useState([])
    const [userDetails, setUserDetails] = useState(null)

    const getItemsData = async () => {
        await dispatch(getItems())
        await dispatch(getSubItems())
        await dispatch(getDeliverySlots())
    }
    
    useEffect(() => {
        if(!items.length) {
            getItemsData()
        }
    }, [items])

    const getUsersData = async () => {
        await dispatch(getUsers({user_type: 'customer', isActive: true}))
    }
    useEffect(() => {
        if(!users.length) {
            getUsersData()
        } else if(users[0].user_type != 'customer') {
            getUsersData()
        }
    }, [users])
    
    const updatePickup = async(checked) => {
        setIsPickFromKitchen(checked)
        if(checked) {
            setAddress({
                "id": 1,
                "userId": 7,
                "fName": "tet",
                "lName": "tt",
                "mobile": "1212121212",
                "email": "admin@test.com",
                "address": "tete",
                "address1": "te",
                "latitude": "-3.754592457083707",
                "longitude": "-38.521626708984385",
                "city": null,
                "zipcode": "500084",
                "isDefault": true,
                "created_at": null
            })
            setAddressId(1)
        } else {
            setAddress(null)
            setAddressId(0)
        }
        
        
    }

    const getSubscriptionsData = async () => {
      await dispatch(getSubscriptions({}))
    }
    useEffect(() => {
        if(!subscriptions.length) {
          getSubscriptionsData()
        }
    }, [subscriptions])

    
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
            event.preventDefault();
            let orderObj = {
                userId: userId,
                itemId: itemId,
                itemName: selectedItem.name,
                subItems: JSON.parse(mappings[0].subItemIds),
                quantity: quantity,
                addressId: addressId,
                price: price,
                totalAmount: totalPrice,
                customerName: address.fName + " " + address.lName,
                customerMobile: address.mobile,
                address: address.address + " " + address.address1 + " " + address.state + " " + address.zipcode,
                zipcode: address.zipcode,
                startDate: startDate,
                deliverySlot: deliverySlot,
                status: 'new',
                orderType: 'normal',
                extraSubItems: extraSubItems,
                latitude: address.latitude,
                longitude: address.longitude,
                isPickFromKitchen: isPickFromKitchen
            }
            console.log("oreder req")
            console.log(orderObj)
            await dispatch(addUserOrder(orderObj))
            navigate('/orders')
        }
        setValidated(true);
        
    };

    const selectItem = async () => {
        let mps = await dispatch(itemMappingsData({itemId: itemId}))
        // console.log("mps", selectedItem.id)
        console.log(mps)
        if(mps.payload) {
            setMappings(mps.payload.data.items)
        }
    }
    useEffect(() => {
        selectItem()
    }, [itemId])

    useEffect(() => {
        if(selectedSubscription && selectedSubscription.id) {
            setPrice(selectedSubscription.price)
            setTotalPrice(selectedSubscription.days*selectedSubscription.price)
        } else if(selectedItem){
            setPrice(selectedItem.price)
            setTotalPrice(selectedItem.price)
        }
    }, [selectedSubscription])

    useEffect(() => {
        if(selectedItem.id && !selectedSubscription) {
            setPrice(selectedItem.price)
            setTotalPrice(selectedItem.price)
        }
    }, [selectedItem])

    const handleClose = async () => {
        setShow(false);
        let extraSubData = []
        let addedExtraitems = []
        console.log("extraSubItems")
        console.log(extraSubItems)
        let extraSubItemsData = Object.keys(extraItemCountData)
        console.log(extraItemCountData)
        if(extraSubItemsData.length) {
            // console.log("hhhh")
            // extraSubItemsData.map((extraSubItem) => {
            let totoalPrice = selectedSubscription ? selectedSubscription.price : selectedItem.price
            let noDays = selectedSubscription ? selectedSubscription.days : 1
            for(let extraSubItem of extraSubItemsData){
                // console.log(extraSubItem)
                let subItemData = subItems.filter((subItem) => {
                    return subItem.id == extraSubItem
                })[0]
                if(extraItemCountData[extraSubItem]) {
                    console.log("data ", extraItemCountData[extraSubItem], totoalPrice)
                    totoalPrice = totoalPrice + (subItemData.price * extraItemCountData[extraSubItem])
                    extraSubData.push({itemId: extraSubItem, quantity: extraItemCountData[extraSubItem]})
                    // setExtraSubItems([...extraSubItems, {itemId: extraSubItem, quantity: extraItemCountData[extraSubItem]}])
                    addedExtraitems.push({subItem: subItemData, quantity: extraItemCountData[extraSubItem]})
                } else {
                    totoalPrice = totoalPrice - (subItemData.price * 0)
                    // setExtraSubItems([...extraSubItems, {itemId: extraSubItem, quantity: 0}])
                }
            }
            setExtraSubItems(extraSubData)
            setAddedExtraSubItems(addedExtraitems)
            console.log("totoalPrice")
            console.log(totoalPrice)
            setTotalPrice(noDays*totoalPrice*quantity)
            setPrice(totoalPrice*quantity)
        }
    }

    const updateItemQuantity = async(num, itemId) => {
        // console.log(extraItemCountData)
        let extraItemCount = extraItemCountData
        if(!extraItemCount[itemId]) {
            extraItemCount[itemId] = 0
        }
        extraItemCount[itemId] = (extraItemCount[itemId]+num) > 0 ? (extraItemCount[itemId]+num) : 0;
        // console.log(extraItemCount)
        extraItemCount = JSON.parse(JSON.stringify(extraItemCount))
        setExtraItemCountData(extraItemCount)
      }
    const setItemIdData = async (itemIdD) => {
        let selectedItem = items.filter((sub) => {
            return sub.id == itemIdD
        })[0]
        setItemId(itemIdD)
        setSelectedItem(selectedItem)
    }
    const setSelectedSubIdData = async (itemIdD) => {
        let selectedItem = subscriptions.filter((sub) => {
            return sub.id == itemIdD
        })[0]
        setSelectedSubId(itemIdD)
        setSelectedSubscription(selectedItem)
    }
    const handleShow = () => {
        setShow(true)
    }
    const updateQuantity = async(num) => {
        console.log(price)
        let qty = quantity+num;
        setQuantity(qty > 0 ? qty : 0)
        setTotalPrice(price*qty)
        let noDays = selectedSubscription ? selectedSubscription.days : 1
        setTotalPrice(noDays*price*qty)
        setPrice(price*qty)
      }
    const getLastOrderDate = async () => {
        if(selectedPlan && startDate) {
            let reqObj ={
                noOrders: selectedSubscription.days,
                startDate,
                selectedPlan,
            }
            await dispatch(getOrderDates(reqObj))
        }
    }
    const updatePlan = async (num) => {
        let index = selectedPlan.indexOf(num)
        if(index > -1) {
            let sp = selectedPlan.splice(index, 1);
            setSelectedPlan(sp)
          } else {
            setSelectedPlan([...selectedPlan, num])
          }
        console.log(selectedPlan)
      }
    const setUserIdData = async (usrId) => {
        setUserId(usrId)
        let userDataList = users.filter(user => {
            return user.id == usrId
        })
        let userDetails = userDataList[0];
        setEmail(userDetails.email)
        setMobile(userDetails.mobile)
        setUsername(userDetails.username)
        let addressesRes = await dispatch(getUserAddresses({userId: usrId}))
        console.log(addressesRes.payload)
        let zoneData = await dispatch(getZones())
        if(zoneData.payload.success) {
            console.log("zone data....")
            console.log(zoneData.payload.data.items)
            let resData = zoneData.payload.data.items
            let aZips = []
            if(resData) {
                for(let zone of resData) {
                    aZips.push(zone.zipcode)
                }
            }
            setAllowedZipCodes(aZips)
        }
    }
    const saveAddress = async () => {
        console.log("welcome ")
        console.log(location)
        if(!username || !email || !mobile || !fName || !lName || !address1 || !address2 || !country || !stateValue || !zip) {
            alert("Please enter all mandatory data")
            return
        }
        console.log(allowedZipCodes.indexOf(zip))
        console.log(zip)
        if(allowedZipCodes.indexOf(zip) < 0) {
            alert("Sorry!, we are not allowing orders from this zip")
            return
        }
        if(!location) {
            alert("Please select location from map")
            return
        }
        let addressData = {
            userId: userId,
            email: email,
            mobile: mobile,
            fName,
            lName,
            address: address1,
            address1: address2,
            country,
            state: stateValue,
            zipcode: zip,
            latitude: location.lat,
            longitude: location.lng
        }
        let addressDataRes = await dispatch(addUserAddress(addressData))
        if(addressDataRes.payload.success){
            setAddressId(addressDataRes.payload.data.id)
            setAddress(addressDataRes.payload.data)
            let addressesRes = await dispatch(getUserAddresses({userId: userId}))
            console.log(addressesRes.payload)
            setNewAddress(!newAddress)
        }
    }
  return (
    <div className='container-fluid'>
        <div className='row mx-5'>
            <Card>
                {/* <Card.Title>Add Item</Card.Title> */}
                <div className="card-header  mb-3">
                    <div className="card-title h5">Add Item</div>
                    
                </div>
                <Card.Body>
                    {
                        error ? 
                        <Row>
                            <p>Problem in adding Order!</p>
                        </Row>
                        : null
                    }
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        {
                            (users && users.length) ?
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col sm={2}>
                                    <Form.Label> User </Form.Label>
                                </Col>
                                <Col sm={10}>
                                    <Form.Select defaultValue={userId} value={userId} onChange={(e) => setUserIdData(e.target.value)} required >
                                        <option value="">Select User</option>
                                        {users.map((userData) => {
                                            return (<option key={userData.id} value={userData.id}>{userData.username}</option>)
                                        })}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            : null
                        }
                        {
                            (items && items.length) ?
                            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                <Col sm={2}>
                                    <Form.Label> Item </Form.Label>
                                </Col>
                                <Col sm={10}>
                                    <Form.Select defaultValue={itemId} value={itemId} onChange={(e) => setItemIdData(e.target.value)} required >
                                        <option value="">Select Item</option>
                                        {items.map((itemData) => {
                                            return (<option key={itemData.id} value={itemData.id}>{itemData.name}</option>)
                                        })}
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                            : null
                        }
                        <Row>
                            { itemId ?
                        <div className="cart-item ">
                            <div className='d-flex justify-content-between'>
                                
                                    <div className="px-3 my-3">
                                        <a className="cart-item-product d-flex " href="#">
                                            <div className="cart-item-product-thumb"><img src={selectedItem.image} alt="Product" width={100}/></div>
                                            <div className="cart-item-product-info">
                                                <div className="cart-item-product-title">{selectedItem.name}</div>                                               
                                                <span className='add_extra' onClick={handleShow}><strong>+ Add Extra</strong></span>
                                                {
                                                    (addedExtraSubItems && addedExtraSubItems.length) ?
                                                    <div className='extra_items_block mt-3'>
                                                        <span className='sub_title d-block'><b>Extra items:</b></span>
                                                        {
                                                            addedExtraSubItems.map((addedExtraitem) => {
                                                                return (
                                                                    <span className="d-flex align-items-center extra_item ">
                                                                        <img src={addedExtraitem.subItem.image} width="40" alt=""/>
                                                                        <span>{addedExtraitem.subItem.name}</span>
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        
                                                        
                                                    
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </a>
                                    </div> 
                                    
                            
                            <div className="px-3 my-3 text-center">
                                <div className="cart-item-label">Quantity</div>
                                <div className="added_count" ><span className="count_minus" onClick={() => updateQuantity(-1)}>-</span><span className="count_total">{quantity}</span><span className="count_plus" onClick={() => updateQuantity(1)}>+</span></div>
                                {
                                    (addedExtraSubItems && addedExtraSubItems.length) ?
                                    <div className='extra_items_block d-inline-block mt-3'>
                                        {
                                            addedExtraSubItems.map((addedExtraSubItem) => {
                                                return (
                                                    <div className="added_count mt-4" ><span className="count_total">{addedExtraSubItem.quantity}</span></div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="px-3 my-3 text-center">
                                <div className="cart-item-label">Subtotal</div>
                                <span className="text-xl font-weight-medium sub_total">${price}</span>
                                {
                                    (addedExtraSubItems && addedExtraSubItems.length) ?
                                    <div className='extra_items_block d-inline-block mt-3'>
                                        {
                                            addedExtraSubItems.map((addedExtraSubItem) => {
                                                return (
                                                    <span className="text-xl font-weight-medium sub_total mt-4">${addedExtraSubItem.subItem.price*addedExtraSubItem.quantity}</span>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                    : null
                                }
                            </div>
                            </div>
                            <hr></hr>
                            <div className='total_amt_block d-flex justify-content-end mx-3'>
                                <span  className='mx-4'><b>Total:</b></span>
                                <span className='mx-4'>${totalPrice}</span>
                            </div>
                            <div className='d-md-flex'>
                                {
                                    (deliverySlots && deliverySlots.length) ?
                                    <div className="px-3 my-3 text-center">
                                        <div className="cart-item-label">Choose Delivery Slot</div>
                                        <div className="count-input position-relative" style={{minWidth: '200px'}}>
                                            <span className='position-absolute end-0 top-50 translate-middle d-arrow'><i className="bi bi-chevron-down"></i></span>
                                            <select className="form-control" onChange={(e) => {setDeliverySlot(e.target.value);}}>
                                                <option value="">Select Delivery Slot</option>
                                                {
                                                    deliverySlots.map((deliverySlot) => {
                                                        return (<option value={deliverySlot.id}>{deliverySlot.name}</option>)
                                                    })
                                                }
                                                
                                            </select>
                                        </div>
                                    
                                    </div>
                                    : null
                                }
                                {
                                    lastSubDate ? 
                                    <div className='px-3 my-3 text-center'>
                                        <div className="cart-item-label">End Date</div>
                                        <div className="count-input position-relative">
                                            {lastSubDate}
                                        </div>
                                    </div>
                                    : null
                                }
                                <Form.Group className="px-3 my-3 text-center" controlId="formHorizontalCheck">
                                    <Col sm={12}>
                                        <Form.Check label="Pickup From Kitchen?" checked={isPickFromKitchen} onChange={(e) => updatePickup(e.target.checked)} />
                                    </Col>
                                </Form.Group>
                            </div>
                            
                        </div>
                        : null
                        }
                        </Row>
                        <Row>
                        {
                                userAddresses.length ?
                                <div className="row">
                                   
                                    {
                                        userAddresses.map((userAddress) => {
                                            return (
                                                <div className="row" onClick={() => {setAddressId(userAddress.id); setAddress(userAddress); setNewAddress(false)}}>
                                                    {/* {userAddress.address} */}
                                                    <div className="container">
                                                        <div className="address_block">
                                                        <Form>
                                                            {['radio'].map((type) => (
                                                                <div key={`inline-${type}`} className="mb-3">
                                                                    <div className="d-flex">
                                                                    <Form.Check
                                                                        inline
                                                                        name="group1"
                                                                        type={type}
                                                                        id={`inline-${type}-1`}
                                                                    />
                                                                    <address>
                                                                    {userAddress.fName + " " + userAddress.lName},<br />
                                                                        {userAddress.address},<br></br> {userAddress.address1}, <br></br>{userAddress.zipcode} </address>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            </Form>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : <span className="btn btn2 btn-lg btn-block add_address" onClick={() => setNewAddress(!newAddress)}  id="goToPayment">+ New Address</span>
                            }
                        </Row>
                        {
                                newAddress ?
                            <form className="needs-validation" novalidate="">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="firstName"><span className="text-danger">*</span> First name</label>
                                        <input type="text" className="form-control" id="firstName" placeholder="" value={fName} onChange={(e) => setFName(e.target.value)} required=""/>
                                        <div className="invalid-feedback"> Valid first name is required. </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="lastName"><span className="text-danger">*</span> Last name</label>
                                        <input type="text" className="form-control" id="lastName" placeholder="" value={lName} onChange={(e) => setLName(e.target.value)} required=""/>
                                        <div className="invalid-feedback"> Valid last name is required. </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="username">Username</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">@</span>
                                        </div>
                                        <input type="text" value={username} disabled className="form-control" id="username" placeholder="Username" required=""/>
                                        <div className="invalid-feedback"> Your username is required. </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email"><span className="text-danger">*</span> Email </label>
                                    <input type="email" className="form-control" id="email" placeholder="you@example.com"  value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <div className="invalid-feedback"> Please enter a valid email address for shipping updates. </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="mobile"><span className="text-danger">*</span> Mobile </label>
                                    <input type="mobile" className="form-control" id="mobile" placeholder="Enetr your mobile number"  value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                    <div className="invalid-feedback"> Please enter a valid mobile for shipping updates. </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address"><span className="text-danger">*</span> Address</label>
                                    <input type="text" className="form-control" id="address" placeholder="1234 Main St" required=""  value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                    <div className="invalid-feedback"> Please enter your shipping address. </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address2"><span className="text-danger">*</span> Address 2 </label>
                                    <input type="text" className="form-control" id="address2" placeholder="Apartment or suite"  value={address2} onChange={(e) => setAddress2(e.target.value)}/>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 mb-3">
                                        <label htmlFor="country"><span className="text-danger">*</span> Country</label>
                                        <select className="custom-select d-block w-100" id="country" required=""  value={country} onChange={(e) => setCountry(e.target.value)}>
                                            <option value="">Choose...</option>
                                            <option value="United States">United States</option>
                                        </select>
                                        <div className="invalid-feedback"> Please select a valid country. </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="state"><span className="text-danger">*</span> State</label>
                                        <select className="custom-select d-block w-100" id="state" required=""  value={stateValue} onChange={(e) => setStateValue(e.target.value)}>
                                            <option value="">Choose...</option>
                                            <option value="Texas">Texas</option>
                                        </select>
                                        <div className="invalid-feedback"> Please provide a valid state. </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label htmlFor="zip"><span className="text-danger">*</span> Zip</label>
                                        <input type="text" className="form-control" id="zip" placeholder="" required=""  value={zip} onChange={(e) => setZip(e.target.value)} />
                                        <div className="invalid-feedback"> Zip code required. </div>
                                    </div>
                                </div>
                                <div>
                                    <Map setLocation={setLocation}></Map>
                                </div>
                                <div className="mt-4"><span className="btn btn2 btn-lg btn-block" onClick={() => saveAddress()}  id="goToPayment">Save Address</span></div>
                                <hr className="mb-4"/>
                                {/* <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="same-address"/>
                                    <label className="custom-control-label" htmlFor="same-address">Shipping address is the same as my billing address</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="save-info"/>
                                    <label className="custom-control-label" htmlFor="save-info">Save this information for next time</label>
                                </div>  */}
                                
                            </form>
                            : null
                            }
                        <Button disabled={!addressId || !deliverySlot} type="submit">Add</Button>
                    </Form>
                </Card.Body>
            </Card>
            
        </div>
        <Modal show={showPopup} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title className='m-l-auto'>Add Extra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row className='justify-content-center'>
                <div className='d-flex flex-wrap justify-content-between'>
                {
                    (subItems && subItems.length && mappings && mappings.length) ? 
                        subItems.map((subItem) => {
                            let mps = JSON.parse(mappings[0].subItemIds)
                            if(mps.indexOf(subItem.id.toString()) > -1) {
                                return (
                                    <div className="menu_item d-flex align-items-center justify-content-between">
                                        <span className='d-flex align-items-center'><img src={subItem.image} width="60" alt=""/><span className='d-block'>{subItem.name}</span></span>
                                        <div className="d-flex flex-column flex_ryt"><span className="price mb-2">${subItem.price}</span><div className="added_count"><span className="count_minus" onClick={() => updateItemQuantity(-1, subItem.id)}>-</span><span className="count_total">{extraItemCountData[subItem.id] ? extraItemCountData[subItem.id] : 0}</span><span className="count_plus" onClick={() => updateItemQuantity(1, subItem.id)}>+</span></div></div>
                                    </div>
                                )
                            }
                        })
                    : null
                }
                {/*
                    (subItems.length && mappings.length) ? 
                        subItems.map((subItem) => {
                            let mps = JSON.parse(mappings[0].subItemIds)
                            if(mps.indexOf(subItem.id.toString()) > -1) {
                                return (
                                    <Col xs={6} md={4}>
                                        <div className="menu_item" style={extraSubItems.indexOf(subItem.id) > -1 ? active : inactive} onClick={handleClick(subItem.id)}>
                                            <span><img src={subItem.image} width="40" alt=""/></span><span>{subItem.name}</span>
                                        </div>
                                        
                                    </Col>
                                )
                            }
                            
                        })
                    : null
                */}
                </div>
            </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
            {/* <Button variant="secondary" onClick={handleClose}>
                Close
            </Button> */}
            <Button variant="btn btn2" onClick={handleClose}>
                Add Items
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}

export default AddOrder;
