export const LOGIN = 'auth/login';
export const TOTAL_USERS = 'users/totalUsers';
export const ADD_USER = 'users/addUser';
export const UPDATE_USER = 'users/updateUser';
export const GET_CATEGORIES = 'categories/list';
export const ADD_ITEM = 'items/add';
export const GET_ITEMS = 'items/list';
export const GET_ITEM = 'items/getItem';
export const ADD_SUB_ITEM = 'items/addSubItem';
export const GET_SUB_ITEMS = 'items/getSubItems';
export const GET_SUB_ITEM = 'items/getSubItem';
export const GET_USERS = 'users/getUsers';
export const GET_USER = 'users/getUser';
export const ADD_ORDER = 'orders/addOrder';
export const GET_ORDERS = 'orders/getOrders';
export const GET_ORDER = 'orders/getOrder';
export const UPDATE_ORDER_STATUS = 'orders/updateOrderStatus';
export const UPDATE_ORDER = 'orders/updateOrder';
export const ADD_SUBSCRIPTION = 'subscriptions/addSubscription';
export const GET_SUBSCRIPTIONS = 'subscriptions/getSubscriptions';
export const ADD_ZONE = 'zones/addZone';
export const GET_ZONES = 'zones/getZones';
export const GET_ITEM_MAPPINGS = 'items/getItemMappings';
export const ADD_ITEM_MAPPING = 'items/addItemMapping';
export const DELETE_USER = 'users/deleteUser';
export const MAP_ZONE_USER = 'zones/addUserMapping';
export const GET_MAP_ZONE_USER = 'zones/getUserMapping';
export const GET_DELIVERY_SLOTS = 'zones/getDeliverySlots';
export const GET_DELIVERY_ORDER_DATES = 'orders/getOrderDates';
export const GET_USER_ADDRESSES = 'users/getUserAddresses';
export const ADD_USER_ORDER = 'orders/addUserOrder';
export const DELETE_ITEM = 'items/deleteItem';
export const DELETE_SUB_ITEM = 'items/deleteSubItem';
export const DELETE_SUBSCRIPTION = 'subscriptions/deleteSubscription';
export const GET_TODAY_ORDER_DETAILS = 'orders/getTodayOrdersReport';
export const GET_USERS_SEARCH = 'users/getUsersSearch';
export const USER_SUBSCRIPTIONS = 'subscriptions/getMySubscriptions';
export const UPDATE_MY_SUBSCRIPTION = 'orders/updateMySubscription';
export const DELETE_MY_SUBSCRIPTION = 'orders/deleteMySubscription';
export const GET_SUBSCRIPTION = 'subscriptions/getSubscription';
export const GET_PAYMENTS = 'payments/getPayments';
export const GET_REFUNDS = 'payments/getRefunds';
export const UPDATE_REFUND = 'payments/updateRefund';
export const UPLOAD_DELIVERY_IMAGE = 'orders/uploadDeliveryImage';
export const GET_DASHBOARD_ORDER_DETAILS = 'orders/dashboardDetails';
export const ADD_USER_ADDRESS = 'users/addUserAddress';
